<template>
  <v-container fluid>
    <modal-detalle :objModaldetalle="objModaldetalle"></modal-detalle>
    <modal-impresion :objModalImpresion="objModalImpresion"></modal-impresion>
    <modal-detalle-pago :objModalDetallePago="objModalDetallePago"></modal-detalle-pago>
    <modal-estado-pago :objModalEstadoPago="objModalEstadoPago"></modal-estado-pago>
    <modal-estado-venta :objModalEstadoVenta="objModalEstadoVenta"></modal-estado-venta>
    <modal-direccion :objModalDireccion="objModalDireccion"></modal-direccion>
    <modal-eliminar :objModalEliminar="objModalEliminar"></modal-eliminar>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> GESTIONAR VENTAS CANCELADAS</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <div class="col-12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'ventasCanceladasPanel' }"
                    >Ventas Canceladas</router-link
                  >
                </p>
              </div>

              <v-col cols="12">
                <v-btn
                  small
                  title="Limpiar filtros"
                  fab
                  dark
                  color="blue-grey"
                  depressed
                  @click="limpiarFiltros()"
                >
                  <v-icon dark>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
              <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                <v-select
                  v-model="cmbCantidadRegistros"
                  item-text="texto"
                  item-value="valor"
                  :items="arrCantidadRegistros"
                  label="Cantidad de registros"
                  @change="listarRegistros()"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="12">
                <v-menu :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :value="formatFecha(filtroFechaDesde)"
                      dense
                      clearable
                      label="Desde"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="
                        paginaActual = 1;
                        filtroFechaDesde = '';
                        listarRegistros();
                      "
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filtroFechaDesde"
                    locale="es-es"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" xl="2" lg="2" md="2" sm="12">
                <v-menu :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :value="formatFecha(filtroFechaHasta)"
                      dense
                      clearable
                      label="Hasta"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="
                        paginaActual = 1;
                        filtroFechaHasta = '';
                        listarRegistros();
                      "
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filtroFechaHasta"
                    locale="es-es"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-select
                  clearable
                  v-model="filtroEstadoPago"
                  item-text="nombre"
                  item-value="idestado_pago"
                  :items="cmbEstadoPago"
                  label="Estado Pago"
                  @change="
                    paginaActual = 1;
                    listarRegistros();
                  "
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-select
                  clearable
                  v-model="filtroMetodoPago"
                  item-text="descripcion"
                  item-value="idmetodo_pago"
                  :items="cmbMetodoPago"
                  label="Metodo Pago"
                  @change="
                    paginaActual = 1;
                    listarRegistros();
                  "
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" class="text-left">
                <v-btn class="my-0" color="info" elevation="" @click="filtrar()">
                  <v-icon>mdi-filter</v-icon> Filtrar
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-btn
                  :loading="btnReporteExcel"
                  fab
                  elevation=""
                  @click="reporteExcel()"
                >
                  <v-icon large color="success">mdi-file-excel</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-simple-table dense="" fixed-header="" ref="tblListado">
                  <template v-slot:default>
                    <thead class="text-no-wrap">
                      <tr>
                        <th>GESTIONAR</th>
                        <th>COD. VENTA</th>
                        <th>COD. CLIENTE</th>
                        <th>FEC. REGISTRO</th>
                        <th>HORA REG.</th>
                        <th>FEC. PAGO</th>
                        <th>HORA PAGO</th>
                        <th>NOMBRE Y APELLIDO</th>
                        <th>CORREO</th>
                        <th>DETALLE PEDIDO</th>
                        <th>IMPRIMIR</th>
                        <th>MONTO</th>
                        <th>CUPON</th>
                        <th>METODO PAGO</th>
                        <th>DETALLE DE PAGO</th>
                        <th>ESTADO PAGO</th>
                        <th>EDITAR DIRECCION</th>
                        <th>TIPO DIR.</th>
                        <th>DIRECCIÓN</th>
                        <th>REFERENCIA</th>
                        <th>DEPARTAMENTO</th>
                        <th>PROVINCIA</th>
                        <th>DISTRITO</th>
                        <th>NO ESTABA PRESENTE</th>
                        <th>BOLETA O FACTURA</th>
                        <th>NUMERO</th>
                        <th>RUC</th>
                        <th>RAZON SOCIAL</th>
                      </tr>
                    </thead>
                    <tbody class="text-no-wrap">
                      <tr
                        :class="
                          item.precio_entrega_rapido != null &&
                          item.precio_entrega_rapido != 0
                            ? 'pintar-grilla'
                            : ''
                        "
                        v-for="item in arrRegistrosTabla"
                        :key="item.idventa"
                      >
                        <td>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                dark=""
                                depressed=""
                                color="cyan"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left="">mdi-cursor-default-click</v-icon>
                                Gestionar
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-show="validarPermiso('ventas.editar')"
                                @click="
                                  objModalEstadoVenta = {
                                    idventa: item.idventa,
                                    idestado_control_de_venta: 2,
                                    titulo: 'Finalizadas',
                                    openModal: true,
                                  }
                                "
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-autorenew</v-icon> Ventas Finalizadas
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-show="validarPermiso('ventas.editar')"
                                @click="
                                  objModalEstadoVenta = {
                                    idventa: item.idventa,
                                    idestado_control_de_venta: 1,
                                    titulo: '',
                                    openModal: true,
                                  }
                                "
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-autorenew</v-icon> Ventas
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-show="validarPermiso('ventas.eliminar')"
                                @click="
                                  objModalEliminar = {
                                    idventa: item.idventa,
                                    openModal: true,
                                  }
                                "
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-delete</v-icon> Eliminar
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                        <td v-text="item.codigo_venta"></td>
                        <td v-text="item.cliente.codigo_cliente"></td>
                        <td>
                          <span
                            v-if="item.fecha_registro != null"
                            v-text="$moment(item.fecha_registro).format('DD/MM/YYYY')"
                          ></span>
                        </td>
                        <td>
                          <span
                            v-if="item.fecha_registro != null"
                            v-text="$moment(item.fecha_registro).format('hh:mm:ss A')"
                          ></span>
                        </td>
                        <td>
                          <span
                            v-if="item.fecha_pago != null"
                            v-text="$moment(item.fecha_pago).format('DD/MM/YYYY')"
                          ></span>
                        </td>
                        <td>
                          <span
                            v-if="item.fecha_pago != null"
                            v-text="$moment(item.fecha_pago).format('hh:mm:ss A')"
                          ></span>
                        </td>
                        <td
                          v-text="item.cliente.nombres + ' ' + item.cliente.apellidos"
                        ></td>
                        <td v-text="item.cliente.correo"></td>
                        <td>
                          <v-btn
                            @click="
                              objModaldetalle = {
                                idventa: item.idventa,
                                idcliente: item.cliente_idcliente,
                                openModal: true,
                              }
                            "
                            elevation=""
                            color="info"
                            icon
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-btn
                            @click="
                              objModalImpresion = {
                                idventa: item.idventa,
                                openModal: true,
                              }
                            "
                            elevation=""
                            color="info"
                            icon
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </td>
                        <td v-text="item.total_final"></td>
                        <td v-text="item.cupon.nombre"></td>
                        <td v-text="item.metodoPago.descripcion"></td>
                        <td>
                          <v-btn
                            @click="
                              objModalDetallePago = {
                                idventa: item.idventa,
                                openModal: true,
                              }
                            "
                            elevation=""
                            color="info"
                            icon
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-btn
                            @click="
                              objModalEstadoPago = {
                                idventa: item.idventa,
                                idestado_pago: item.idestado_pago,
                                openModal: true,
                              }
                            "
                            text
                            elevation=""
                            color="info"
                            v-text="item.estadoPago.nombre"
                          >
                          </v-btn>
                        </td>
                        <td>
                          <v-btn
                            @click="
                              objModalDireccion = {
                                idventa: item.idventa,
                                openModal: true,
                              }
                            "
                            elevation=""
                            color="info"
                            icon
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </td>
                        <td v-text="item.tipoDireccion.nombre"></td>
                        <td v-text="item.direccion_direccion"></td>
                        <td v-text="item.direccion_referencia"></td>
                        <td v-text="item.ubigeoDepartamento"></td>
                        <td v-text="item.ubigeoProvincia"></td>
                        <td v-text="item.ubigeoDistrito"></td>
                        <td v-text="item.receptor_pedido"></td>
                        <td v-text="item.comprobante.nombre"></td>
                        <td v-text="item.facturacion_nro_comprobante"></td>
                        <td v-text="item.facturacion_ruc"></td>
                        <td v-text="item.facturacion_razonsocial"></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <p>
                  Mostrando del registro <span v-text="primerRegistro"></span> al
                  <span v-text="ultimoRegistro"></span> de un total de
                  <span v-text="totalRegistro"></span> filas
                </p>
              </v-col>

              <v-col cols="12 text-center" v-show="loaderListado">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>

              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-pagination
                  circle
                  v-model="paginaActual"
                  :length="cantidadprobadorVirtuales"
                  :total-visible="7"
                  @input="listarRegistros()"
                  :disabled="loaderListado"
                ></v-pagination>
              </v-col>

              <v-col cols="12" v-show="alertRegistroNoEcontrado">
                <v-alert type="error" dense outlined class="text-center">
                  NO HAY REGISTROS DISPONIBLES PARA MOSTRAR
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import modalDetalle from "./Detalle";
import modalImpresion from "./Impresion";
import modalDetallePago from "./DetallePago";
import modalEstadoPago from "./EstadoPago";
import modalEstadoVenta from "./EstadoVenta";
import modalDireccion from "./Direccion";
import modalEliminar from "./Eliminar";
export default {
  components: {
    modalDetalle,
    modalImpresion,
    modalDetallePago,
    modalEstadoPago,
    modalEstadoVenta,
    modalDireccion,
    modalEliminar,
  },
  data() {
    return {
      arrCantidadRegistros: [
        {
          texto: 10,
          valor: 10,
        },
        {
          texto: 30,
          valor: 30,
        },
        {
          texto: 50,
          valor: 50,
        },
        {
          texto: 100,
          valor: 100,
        },
        {
          texto: 500,
          valor: 500,
        },
      ],
      loaderListado: false,
      alertRegistroNoEcontrado: false,
      cmbCantidadRegistros: 10,
      arrRegistrosTabla: [],
      cmbEstadoPago: [],
      cmbMetodoPago: [],
      cmbTipoDireccion: [],
      paginaActual: 1,
      cantidadprobadorVirtuales: 1,
      primerRegistro: 0,
      ultimoRegistro: 0,
      totalRegistro: 0,
      iptBuscarRegisro: "",
      filtroFechaDesde: "",
      filtroFechaHasta: "",
      filtroEstadoPago: "",
      filtroMetodoPago: "",
      objModaldetalle: null,
      objModalImpresion: null,
      objModalDetallePago: null,
      objModalEstadoPago: null,
      objModalEstadoVenta: null,
      objModalDireccion: null,
      objModalEliminar: null,
      btnReporteExcel: false,
    };
  },

  methods: {
    formatFecha(fecha) {
      return fecha ? this.$moment(fecha).format("DD/MM/YYYY") : "";
    },

    listarRegistros() {
      this.arrRegistrosTabla = [];
      this.loaderListado = true;
      this.alertRegistroNoEcontrado = false;
      this.axios({
        method: "GET",
        url: "api/panel/ventas/v1",
        params: {
          cantidadRegistros: this.cmbCantidadRegistros,
          paginaActual: this.paginaActual,
          filtroFechaDesde: this.filtroFechaDesde,
          filtroFechaHasta: this.filtroFechaHasta,
          filtroEstadoPago: this.filtroEstadoPago,
          filtroMetodoPago: this.filtroMetodoPago,
          idestado_control_de_venta: 3,
        },
      })
        .then((response) => {
          let listado = response.data.data;

          this.arrRegistrosTabla = listado.data;
          this.paginaActual = listado.current_page;
          this.cantidadprobadorVirtuales = listado.last_page;
          this.primerRegistro = listado.from;
          this.ultimoRegistro = listado.to;
          this.totalRegistro = listado.total;

          if (this.arrRegistrosTabla.length === 0) {
            this.alertRegistroNoEcontrado = true;
          } else {
            this.alertRegistroNoEcontrado = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .then(() => {
          this.loaderListado = false;
          this.$vuetify.goTo(this.$refs.tblListado);
        });
    },

    getFiltros() {
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v1/filtros",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.cmbEstadoPago = response.data.data.estadoPago;
            this.cmbMetodoPago = response.data.data.metodoPago;
            this.cmbTipoDireccion = response.data.data.tipoDireccion;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    reporteExcel() {
      window.open(
        this.apiUrl(
          "api/panel/ventas/v1/reporteExcel?filtroFechaDesde=" +
            this.filtroFechaDesde +
            "&filtroFechaHasta=" +
            this.filtroFechaHasta +
            "&filtroEstadoPago=" +
            this.filtroEstadoPago +
            "&filtroMetodoPago=" +
            this.filtroMetodoPago +
            "&idestado_control_de_venta=" +
            "3"
        ),
        "_self"
      );

      /*this.btnReporteExcel = true;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v1/reporteExcel",
        responseType: "blob",
        data: {
          filtroFechaDesde: this.filtroFechaDesde,
          filtroFechaHasta: this.filtroFechaHasta,
          filtroEstadoPago: this.filtroEstadoPago,
          filtroMetodoPago: this.filtroMetodoPago,
          idestado_control_de_venta: 3,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let objUrl = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.setAttribute("href", objUrl);
            link.setAttribute(
              "download",
              "reporte_ventas_canceladas_" + this.$moment().format("DD-MM-YYYY_hh-mm-ss")
            );
            link.click();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.btnReporteExcel = false;
        });*/
    },

    filtrar() {
      this.paginaActual = 1;
      this.listarRegistros();
    },

    limpiarFiltros() {
      this.cmbCantidadRegistros = 10;
      this.filtroFechaDesde = "";
      this.filtroFechaHasta = "";
      this.filtroEstadoPago = "";
      this.filtroMetodoPago = "";
      this.listarRegistros();
    },
  },

  created() {
    if (!this.validarPermiso("ventas.listado")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    this.listarRegistros();
    this.getFiltros();
  },
};
</script>
<style scoped>
.pintar-grilla {
  background-color: rgb(230, 128, 128) !important;
}
</style>
