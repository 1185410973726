<template>
  <div>
    <v-dialog v-model="openModal" max-width="500">
      <v-card class="pa-5">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="mb-0 font-weight-bold">
                ¿Estas reguro que desea eliminar esta venta?
              </p>
            </v-col>
            <v-col cols="12" class="text-center mt-2">
              <v-btn
                :loading="processForm"
                class="mr-2"
                color="error"
                outlined=""
                @click="openModal = false"
              >
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
              <v-btn :loading="processForm" color="success" @click="eliminarVenta">
                <v-icon left="">mdi-check-bold</v-icon> Confirmar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      processForm: false,
      idventa: "",
    };
  },
  props: {
    objModalEliminar: {
      type: Object,
    },
  },
  watch: {
    objModalEliminar(val) {
      this.openModal = val.openModal;
      this.idventa = val.idventa;
    },
  },

  methods: {
    eliminarVenta() {
      this.processForm = true;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v1/eliminarVenta",
        data: {
          idventa: this.idventa,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.openModal = false;

            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });

            this.$parent.listarRegistros();
          }
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al eliminar este registro", {
            icon: "mdi-close",
          });
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
};
</script>
